.home {
  &__container {
    transition: max-width 0.2s ease-in-out;
  }

  &__hero {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 30px;

    &-image {
      width: 100%;
      height: auto;
    }
  }
}
