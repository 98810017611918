$navbar-height: 64px;

// Colors
$color-white: #fff;
$color-black: #000;
$color-black-primary: rgba(0, 0, 0, 0.87);
$color-blue-primary: #2196f3;

// breakpoints
$xs: 0;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;
