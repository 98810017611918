.activities {
  justify-content: center;

  &__item {
    align-items: center;
  }

  &__card {
    display: flex;
    width: 90%;
    min-width: 175px;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;
  }

  &__card-image {
    height: 140px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
