@use 'App.scss';

.carousel {
  position: relative;

  & > div > div:first-child {
    height: 50vh !important;

    @media (max-aspect-ratio: 1/1) and (max-width: 750px) {
      height: 60vw !important;
    }
  }

  &__mui-carousel {
    img {
      width: 100%;
      height: 100%;
      max-height: 50vh;
      object-fit: cover;
    }
  }

  &__loader {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: App.$color-white;
  }

  &__skeleton {
    width: 100%;
    height: 100% !important;
  }
}
