@use 'App.scss';

.gym-markers {
  &__label{
    width: 39px;
    margin-left: 100%;
    font-size: 15px;
    -webkit-text-stroke: 1px black;
  }
}
