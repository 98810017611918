@use 'App.scss';

.contact {
  width: 100%;
  margin: 2rem 0;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: App.$md) {
      flex-direction: column-reverse;
      gap: 1rem;
    }
  }

  &__links {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    p {
      margin: 0 1rem;
    }

    a {
      color: App.$color-black-primary;
    }
  }

  &__link-icon {
    color: App.$color-blue-primary;
  }

  &__link-url {
    display: inline-block;
  }

  &__open-map-button {
    @media (max-width: App.$md) {
      margin: 0;
    }
  }
}
