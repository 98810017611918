@use 'App.scss';

.markers-map {
  &__container {
    width: 100%;
    height: calc(100dvh - App.$navbar-height);
  }

  &__loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
