@use 'App.scss';

.mini-map {
  &__container {
    width: 100%;
    height: 30vh;

    @media (max-width: App.$md) {
      margin: 1rem 0;
    }
  }
}
